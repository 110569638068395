<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="anuadmins"
            :search="search"
            sort-by="email"
            class="elevation-10"
            dense
        >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>anuadmins</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="mr-5"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
                
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    
                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo anuadmin
                    </v-btn>
                  </template>
          
                  <v-card>
                    <v-card-title>
                    <span class="text-h5">Nuevo anuadmin</span>
                    </v-card-title>

                    <v-card-text class="text-center">
                      <v-progress-circular
                        v-if="showLoading == true"
                        indeterminate
                        color="primary"
                        size="70"
                        width="7"  
                      ></v-progress-circular>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.email"
                              label="email"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="close"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="saveAnuadmin"
                          :disabled="editedItem.email == ''"
                        >
                          Guardar
                        </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                  <v-card>
                    <v-card-title class="text-h6 justify-center">¿Quieres eliminar el usuario<br>"{{editedItem.email}}"?</v-card-title>
                    <v-card-text class="text-center">
                      <v-progress-circular
                        v-if="showLoading == true"
                        indeterminate
                        color="primary"
                        size="70"
                        width="7"  
                      ></v-progress-circular>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-toolbar>
            </template>


            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                @click="deleteItem(item)"
                color="red"
              >
                mdi-delete
              </v-icon>
          </template>
        </v-data-table>

      </v-col>
    </v-row>


  </v-container>
</template>

<script>
//import {auth} from "../firebase"
//import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth"
import {db} from '../firebase'
import { collection, query, where, onSnapshot, doc, setDoc, deleteDoc } from "firebase/firestore";
export default {
  data() {
    return {
      showLoading: false,
      anuadmins: [],
      search: '',
      headers: [
        { text: 'Usuario', align: 'start', value: 'email'},
        { text: 'Role', value: 'role'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      editedItem: {
        email: '',
      },
      defaultItem: {
        email: '',
      },
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
    }
  },

  methods: {
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItem (item) {
      this.editedIndex = this.anuadmins.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      try {
        this.showLoading = true
        await deleteDoc(doc(db, "users", this.editedItem.email))
        this.closeDelete()
        this.showLoading = false
      } catch (error) {
        console.log(error)
      }
      
    },

    async saveAnuadmin (){
      try {
        this.showLoading = true
        //FIRESTORE
        await setDoc(doc(db, "users", this.editedItem.email),{
          email: this.editedItem.email,
          role: "anuadmin"
        })
        this.close()
        this.showLoading = false
      } catch (error) {
        console.log(error)
      }
    },

    getAnuadmins(){
      const q = query(collection(db, "users"), where("role", "==", "anuadmin"))
      const unsubscribe = onSnapshot(q, querySnapshot =>{
        this.anuadmins = []
        querySnapshot.forEach(item => {
          this.anuadmins.push(item.data())
        })
      })
    },

  },

  created() {
    this.getAnuadmins()
    
  },
  

}
</script>

<style>

</style>